import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import InputMask from "react-input-mask";
import useStyles from "./styles";
import { getCep, getCepOutrosEnderecos } from "../../../api/Endereco";
import { styled } from "@mui/material/styles";
import useAlert from "../../../hooks/useAlert";
import { Box, Skeleton } from "@mui/material";

const CssTextField = styled(TextField)({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderRadius: "4px !important",
    },
    "&:hover fieldset": {
      borderRadius: "4px !important",
    },
  },
});

export default function CampoCep({
  name,
  value,
  handlerChange,
  label,
  size,
  setEndereco,
  idCliente,
  idTipoEndereco,
  fullWidth,
  recarregar = true,
  setTextError = null,
  isEnderecoCliente = true,
}) {
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const classes = useStyles();
  const { showAlertError } = useAlert();

  useEffect(() => {
    const valueOrigin = value.replace(/\D+/g, "");
    const isValid = valueOrigin.length === 8;
    setIsError(!isValid);
    if (isValid && recarregar) {
      handlerSetEndereco(valueOrigin);
    }
  }, [value]);

  const handlerSetEndereco = async (valueOrigin) => {
    setIsloading(() => true);
    var data = null;
    if (isEnderecoCliente)
      data = await getCep(valueOrigin, idCliente, idTipoEndereco);
    else data = await getCepOutrosEnderecos(valueOrigin);

    if (!!data?.errorMessage) {
      if (setTextError) {
        setTextError(`Ops, ${data?.errorMessage} - RAX08`);
        return;
      }
      showAlertError(`Ops, ${data?.errorMessage} - RAX08 `);
      return;
    }
    setEndereco(data);
    setIsloading(() => false)
  };

  return (
    <>
      {
        isLoading ?
          <Skeleton animation="wave" height={60} />
          :
          <InputMask
            onChange={(event) => handlerChange(event)}
            value={value}
            mask="99999-999"
            unmask={true}
            maskChar="_"
          >
            {(props) => (
              <CssTextField
                required
                fullWidth={fullWidth}
                id={label}
                label={label}
                size={size}
                name={name}
                value={value}
                onChange={props?.handlerChange}
                className={classes.root}
              />
            )}
          </InputMask>

      }

    </>
  );
}
