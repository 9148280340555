import { Fragment } from "react";
import CampoTexto from "../../../Campos/CampoTexto";
import CampoNumber from "../../../Campos/CampoNumber";
import TextField from "@mui/material/TextField";
import InputMask from "react-input-mask";
import Seletor from "../../../Seletor";
import Subtitulo from "../../../Textos/Subtitulo";
import { Box, Grid, Skeleton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import useStyle from "../styles";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import CustomGrid from "../../../GridCadastroCustom";
import IconButtonCustom from "../../../Botoes/IconButtonCustom";
import AddIcon from "@mui/icons-material/Add";
import IconButtonDelete from "../../../Botoes/IconButtonDelete";
import { PositionTooltip, TooltipInformacao } from "../../../TooltipInformacao";
import EnderecoPrincipal from "../../EnderecoPrincipal";
import Arquivo from "../../Arquivo";
import theme from "../../../Paleta/theme";

export default function SocioForm({
  identificaItem,
  setData,
  setDadosForm,
  dadosForm,
  isLoading,
  setIdTipoEstadoCivil = 1,
  setIdTipoSocio = 0,
  initBuscaCampos,
  buscaCamposNovoSocio,
  title,
  tooltipAdd,
  tooltipDelete,
  idTipoSocio,
  idTipoEstadoCivil,
  idNacionalidade,
  setIdNacionalidade = 0,
}) {
  const classes = useStyle();
  const deepCloning = (obj) => {
    if (!!obj) return JSON.parse(JSON.stringify(obj));

    return [];
  };

  const handlerChangeValue = (e, index) => {
    let dados = deepCloning(dadosForm);

    dados[index].campos = dados[index]?.campos?.map((c) => {
      if (e.target.name === c.propriedade) c.valor = e.target.value;

      return c;
    });

    setDadosForm(() => [...dados]);
    setData(() => [...dados]);
  };

  const handleChangeCheck = (e, index) => {
    let newValue = deepCloning(dadosForm);

    newValue[index].campos = newValue[index]?.campos?.map((c) => {
      if (c.propriedade === e.target.name)
        return { ...c, valor: e.target.checked };
      else return c;
    });

    setDadosForm(() => [...newValue]);
    setData(() => [...newValue]);
  };

  const handleChangeCheckAssinaturaContrato = (e, index) => {
    let newValue = deepCloning(dadosForm);

    newValue[index].assinaturaContratoSocio.assinaContrato.valor =
      e.target.checked;

    setDadosForm(() => [...newValue]);
    setData(() => [...newValue]);
  };

  const handlerChangeValueAssinaturaContrato = (e, index) => {
    let dados = deepCloning(dadosForm);

    dados[index].assinaturaContratoSocio.camposAssinatura = dados[
      index
    ]?.assinaturaContratoSocio?.camposAssinatura?.map((c) => {
      if (e.target.name === c.propriedade) c.valor = e.target.value;

      return c;
    });

    setDadosForm(() => [...dados]);
    setData(() => [...dados]);
  };

  const handlerChangeSelect = async (name, value, index) => {
    let dados = deepCloning(dadosForm);

    dados = dados?.map((item, i) => {
      if (i === index) item[name] = value;

      return item;
    });

    setDadosForm(() => [...dados]);
    setData(() => [...dados]);

    if (name === "idTipoEstadoCivil") {
      setIdTipoEstadoCivil(value);
      await initBuscaCampos(value, idTipoSocio, idNacionalidade, dados, index);
    }

    if (name === "idNacionalidade") {
      setIdNacionalidade(value);
      await initBuscaCampos(
        idTipoEstadoCivil,
        idTipoSocio,
        value,
        dados,
        index
      );
    }

    if (name === "idTipoSocio") {
      setIdTipoSocio(value);
      await initBuscaCampos(
        idTipoEstadoCivil,
        value,
        idNacionalidade,
        dados,
        index
      );
    }
  };

  const handlerChangeValuePercentual = (value, propriedade, index) => {
    let dados = deepCloning(dadosForm);

    dados[index].campos = dados[index]?.campos?.map((obj) => {
      if (obj.propriedade === propriedade) {
        return {
          ...obj,
          valor: value,
        };
      }
      return obj;
    });

    setDadosForm(() => [...dados]);
    setData(() => [...dados]);
  };

  const limparDefaultCampos = (controle) => {
    let newData = controle;

    newData[0].idSocio = 0;

    newData.endereco = {
      cep: "",
      bairro: "",
      cidade: "",
      complemento: "",
      logradouro: "",
      idPais: 0,
      idUf: 0,
      numero: "0",
    };

    if (newData?.length > 0) {
      newData[0].campos = controle[0]?.campos?.map((c) => {
        if (c?.tipoControle?.toUpperCase() === "DATA") c.valor = Date.now();
        else if (c?.tipoControle?.toUpperCase() === "NUMERO") c.valor = 0;
        else if (c?.tipoControle?.toUpperCase() === "CHECK") c.valor = false;
        else c.valor = "";

        return c;
      });
    }

    return newData;
  };

  const handlerAddCampos = async () => {
    let defaultControles = await buscaCamposNovoSocio(
      idTipoEstadoCivil,
      idTipoSocio,
      idNacionalidade,
      true
    );

    let valorDefault = limparDefaultCampos(deepCloning([defaultControles]));

    setDadosForm((prevState) => {
      if (prevState.length > 0 && prevState[0]?.campos?.length > 0) {
        return [...prevState, valorDefault[0]];
      } else {
        let array = [valorDefault[0]];
        return [...array];
      }
    });

    setData((prevState) => {
      if (prevState.length > 0 && prevState[0]?.campos?.length > 0)
        return [...prevState, valorDefault[0]];

      let array = [valorDefault[0]];
      return [...array];
    });

    setTimeout(() => {
      var tamanhoPagina = document.body.scrollHeight;
      window.scrollTo(0, tamanhoPagina + 30);
    }, 200);
  };

  const handlerRemoveCampos = (index) => {
    setDadosForm((prevState) => {
      return prevState.filter((_, i) => i !== index);
    });
    setData((prevState) => {
      return prevState.filter((_, i) => i !== index);
    });
  };

  const handlerChangeEndereco = (enderecoNew, index) => {
    let dados = deepCloning(dadosForm);

    dados[index].endereco = enderecoNew;

    setDadosForm(() => [...dados]);
    setData(() => [...dados]);
  };

  const handlerChangeDocumento = (documentosNew, index) => {
    let dados = deepCloning(dadosForm);
    dados[index].documentos = documentosNew;
    setDadosForm(() => [...dados]);
    setData(() => [...dados]);
  };

  return (
    <>
      <div className={classes.box}>
        {isLoading ? (
          <Skeleton animation="wave" width={"10%"} height={30} sx={{ mb: 2 }} />
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Subtitulo color="primary" label={title} />
            <IconButtonCustom onClick={handlerAddCampos}>
              <TooltipInformacao
                positionStart={PositionTooltip.left}
                descricao={tooltipAdd}
              >
                <AddIcon sx={{ fontSize: "1.2rem" }} color="inherit" />
              </TooltipInformacao>
            </IconButtonCustom>
          </Box>
        )}

        <Grid
          container
          rowSpacing={1}
          sx={{ alignItems: "center" }}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          {!!dadosForm?.length &&
            dadosForm?.map((dado, index) => (
              <Fragment key={`dadosForm${index}`}>
                <div
                  style={{
                    height: 1,
                    backgroundColor: theme.palette.secondary.light,
                    width: "100%",
                    opacity: 0.3,
                    marginBottom: 10,
                    marginLeft: 25,
                    marginTop: 10,
                  }}
                />
                <Grid item xs={12} md={12} sx={index > 0 ? { mt: 0 } : {}}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "start",
                      alignItems: "center",
                    }}
                  >
                    <Subtitulo
                      color="secondary"
                      sx={{ mb: 2 }}
                      label={`${index + 1}º  ${identificaItem}`}
                    />
                    {dadosForm.length > 1 && index > 0 && (
                      <TooltipInformacao
                        positionStart={PositionTooltip.right}
                        descricao={tooltipDelete}
                      >
                        <span>
                          <IconButtonDelete
                            onClick={() => handlerRemoveCampos(index)}
                          />
                        </span>
                      </TooltipInformacao>
                    )}
                  </Box>
                </Grid>
                {dado?.enableNacionalidade &&
                  dado?.idNacionalidade > 0 &&
                  dado?.idNacionalidade && (
                    <Grid item xs={6} md={6}>
                      <Seletor
                        isLoading={isLoading}
                        setSelect={(e) =>
                          handlerChangeSelect("idNacionalidade", e, index)
                        }
                        list={dado.nacionalidades}
                        size="medium"
                        key={"nacionalidades"}
                        label="Nacionalidade"
                        fullWidth={true}
                        value={dado?.idNacionalidade}
                      />
                    </Grid>
                  )}
                {dado?.enableTipoCliente &&
                  dado?.idTipoCliente &&
                  dado?.idTipoCliente > 0 && (
                    <Grid item xs={6} md={6}>
                      <Seletor
                        isLoading={isLoading}
                        setSelect={(e) =>
                          handlerChangeSelect("idTipoCliente", e, index)
                        }
                        list={dado.tiposClientes}
                        size="medium"
                        key={"tiposClientes"}
                        label="Tipo Cliente"
                        fullWidth={true}
                        value={dado?.idTipoCliente}
                      />
                    </Grid>
                  )}

                {dado?.enableTipoSocio && dado?.idTipoSocio > 0 && (
                  <Grid item xs={6} md={6}>
                    <Seletor
                      isLoading={isLoading}
                      setSelect={(e) =>
                        handlerChangeSelect("idTipoSocio", e, index)
                      }
                      list={dado.tiposSocios}
                      size="medium"
                      key={"tipoSocio"}
                      label="Tipo de Sócio"
                      fullWidth={true}
                      value={dado?.idTipoSocio}
                    />
                  </Grid>
                )}
                {dado?.enableTipoEstadoCivil &&
                  dado?.idTipoEstadoCivil > 0 &&
                  dado?.idTipoEstadoCivil && (
                    <Grid item xs={6} md={6}>
                      <Seletor
                        isLoading={isLoading}
                        setSelect={(e) =>
                          handlerChangeSelect("idTipoEstadoCivil", e, index)
                        }
                        list={dado.tiposEstadoCivil}
                        size="medium"
                        key={"tipoEstadoCivil"}
                        label="Tipo Estado Civil"
                        fullWidth={true}
                        value={dado?.idTipoEstadoCivil}
                      />
                    </Grid>
                  )}

                {dado?.campos &&
                  dado?.campos?.map(
                    (c, i, array) =>
                      c.enable &&
                      c.propriedade !== "Cep" && (
                        <Fragment key={`campos${i}`}>
                          <CustomGrid index={i} array={array} md={6}>
                            {c.tipoMascara === "CPF" &&
                              (isLoading ? (
                                <Skeleton
                                  animation="wave"
                                  width={"100%"}
                                  height={60}
                                />
                              ) : (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <InputMask
                                    onChange={(e) =>
                                      handlerChangeValue(e, index)
                                    }
                                    mask={"999.999.999-99"}
                                    value={c.valor}
                                    enable={c.enable}
                                    maskChar="_"
                                  >
                                    {({ value, onChange }) => (
                                      <TextField
                                        spellCheck="false"
                                        margin="normal"
                                        required={c.obrigatorio}
                                        fullWidth={true}
                                        label={c.label}
                                        maxLength={c.maxLength}
                                        name={c.propriedade}
                                        value={value}
                                        size="medium"
                                        // className={classes.campo}
                                        autoFocus
                                        onChange={onChange}
                                      />
                                    )}
                                  </InputMask>
                                  {c.informacao && (
                                    <Tooltip title={c.informacao}>
                                      <IconButton>
                                        <InfoIcon
                                          fontSize="small"
                                          color="primary"
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </Box>
                              ))}
                              {c.tipoMascara === "CNPJ" &&
                              (isLoading ? (
                                <Skeleton
                                  animation="wave"
                                  width={"100%"}
                                  height={60}
                                />
                              ) : (
                                <>
                                  <InputMask
                                    onChange={(e) =>
                                      handlerChangeValue(e, index)
                                    }
                                    mask={"99.999.999/9999-99"}
                                    value={c.valor}
                                    enable={c.enable}
                                    maskChar="_"
                                  >
                                    {({ value, onChange }) => (
                                      <TextField
                                        spellCheck="false"
                                        margin="normal"
                                        required={c.obrigatorio}
                                        fullWidth={true}
                                        label={c.label}
                                        maxLength={c.maxLength}
                                        name={c.propriedade}
                                        value={value}
                                        size="medium"                                        
                                        autoFocus
                                        onChange={onChange}
                                      />
                                    )}
                                  </InputMask>
                                </>
                              ))}

                            {c.tipoControle === "Texto" &&
                              c.tipoMascara === null &&
                              (isLoading ? (
                                <Grid sx={{ mt: 1 }}>
                                  <Skeleton
                                    animation="wave"
                                    width={"100%"}
                                    height={60}
                                  />
                                </Grid>
                              ) : (
                                <Grid sx={{ mt: 1 }}>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "row",
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <CampoTexto
                                      enable={c.enable}
                                      variant="filled"
                                      size="medium"
                                      fullWidth={true}
                                      value={c.valor}
                                      defaultValue=" "
                                      label={c.label}
                                      name={c.propriedade}
                                      handlerChange={(e) =>
                                        handlerChangeValue(e, index)
                                      }
                                      className={classes.campo}
                                      maximoDigitos={c.maxLength}
                                      required={c.obrigatorio}
                                    />
                                    {c.informacao && (
                                      <Tooltip title={c.informacao}>
                                        <IconButton>
                                          <InfoIcon
                                            fontSize="small"
                                            color="primary"
                                          />
                                        </IconButton>
                                      </Tooltip>
                                    )}
                                  </Box>
                                </Grid>
                              ))}

                            {c.tipoControle === "Check" &&
                              (isLoading ? (
                                <Skeleton
                                  animation="wave"
                                  width={"100%"}
                                  height={60}
                                />
                              ) : (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <FormControl
                                    sx={{ mt: 1 }}
                                    component="fieldset"
                                    variant="standard"
                                  >
                                    <FormLabel component="legend">
                                      {c.label}
                                    </FormLabel>
                                    <FormGroup>
                                      <FormControlLabel
                                        control={
                                          <Checkbox
                                            value={c.valor}
                                            checked={c.valor}
                                            onChange={(e) =>
                                              handleChangeCheck(e, index)
                                            }
                                            inputProps={{
                                              "aria-label": "controlled",
                                            }}
                                            name={`${c.propriedade}`}
                                          />
                                        }
                                        label="Sim"
                                      />
                                    </FormGroup>
                                  </FormControl>
                                  {c.informacao && (
                                    <Tooltip title={c.informacao}>
                                      <IconButton>
                                        <InfoIcon
                                          fontSize="small"
                                          color="primary"
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </Box>
                              ))}

                            {c.tipoControle === "Numero" && (
                              <Grid sx={{ mt: 1 }}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                  }}
                                >
                                  <CampoNumber
                                    isLoading={isLoading}
                                    isMonetario={c.tipoMascara === "Monetaria"}
                                    isPercent={
                                      c.tipoMascara === "percentual" ||
                                      c.propriedade === "PercentualCapital"
                                    }
                                    variant="outlined"
                                    size="medium"
                                    enable={c.enable}
                                    fullWidth={true}
                                    label={c.label}
                                    name={c.propriedade}
                                    value={c.valor}
                                    onChange={(value, propriedade) =>
                                      handlerChangeValuePercentual(
                                        value,
                                        propriedade,
                                        index
                                      )
                                    }
                                    className={classes.campo}
                                    maximoDigitos={c.maxLength}
                                    required={c.obrigatorio}
                                  />
                                  {c.informacao && (
                                    <Tooltip title={c.informacao}>
                                      <IconButton>
                                        <InfoIcon
                                          fontSize="small"
                                          color="primary"
                                        />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                </Box>
                              </Grid>
                            )}
                          </CustomGrid>
                        </Fragment>
                      )
                  )}

                <br />

                {dado?.assinaturaContratoSocio?.habilitarAssinaturaContrato && (
                  <Fragment>
                    <Grid item xs={12} md={12}>
                      <Box mt={3}>
                        <Subtitulo sx={{ mb: 2, fontSize: 15, color: "#000" }}>
                          Dados da assinatura de contrato
                        </Subtitulo>
                        {isLoading ? (
                          <Skeleton
                            animation="wave"
                            width={"100%"}
                            height={60}
                          />
                        ) : (
                          <FormControl
                            sx={{ mt: 1 }}
                            component="fieldset"
                            variant="standard"
                          >
                            <FormGroup>
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      value={
                                        dado?.assinaturaContratoSocio
                                          ?.assinaContrato?.valor
                                      }
                                      checked={
                                        dado?.assinaturaContratoSocio
                                          ?.assinaContrato?.valor
                                      }
                                      onChange={(e) =>
                                        handleChangeCheckAssinaturaContrato(
                                          e,
                                          index
                                        )
                                      }
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                      name={`${dado?.assinaturaContratoSocio?.assinaContrato?.propriedade}`}
                                    />
                                  }
                                  label={
                                    dado?.assinaturaContratoSocio
                                      ?.assinaContrato?.label
                                  }
                                />
                                {dado?.assinaturaContratoSocio?.assinaContrato
                                  ?.informacao && (
                                  <Tooltip
                                    title={
                                      dado?.assinaturaContratoSocio
                                        ?.assinaContrato?.informacao
                                    }
                                  >
                                    <IconButton>
                                      <InfoIcon
                                        fontSize="small"
                                        color="primary"
                                      />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </Box>
                            </FormGroup>
                          </FormControl>
                        )}
                      </Box>
                    </Grid>
                    {dado?.assinaturaContratoSocio?.assinaContrato?.valor &&
                      dado?.assinaturaContratoSocio?.camposAssinatura &&
                      dado?.assinaturaContratoSocio?.camposAssinatura?.map(
                        (c, i, array) =>
                          c.enable && (
                            <Fragment key={`campos-assinatura-contrato-${i}`}>
                              {c.tipoControle === "Texto" &&
                                c.tipoMascara === null &&
                                (isLoading ? (
                                  <CustomGrid index={i} array={array} md={6}>
                                    <Skeleton
                                      animation="wave"
                                      width={"100%"}
                                      height={60}
                                    />
                                  </CustomGrid>
                                ) : (
                                  <CustomGrid index={i} array={array} md={6}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        justifyContent: "space-between",
                                        alignItems: "center",
                                      }}
                                    >
                                      <CampoTexto
                                        enable={c.enable}
                                        variant="filled"
                                        size="medium"
                                        fullWidth={true}
                                        value={c.valor}
                                        defaultValue=" "
                                        label={c.label}
                                        name={c.propriedade}
                                        handlerChange={(e) =>
                                          handlerChangeValueAssinaturaContrato(
                                            e,
                                            index
                                          )
                                        }
                                        className={classes.campo}
                                        maximoDigitos={c.maxLength}
                                        required={c.obrigatorio}
                                      />
                                      {c.informacao && (
                                        <Tooltip title={c.informacao}>
                                          <IconButton>
                                            <InfoIcon
                                              fontSize="small"
                                              color="primary"
                                            />
                                          </IconButton>
                                        </Tooltip>
                                      )}
                                    </Box>
                                  </CustomGrid>
                                ))}
                            </Fragment>
                          )
                      )}
                  </Fragment>
                )}

                <Box mt={3}>
                  <EnderecoPrincipal
                    setData={(e) => handlerChangeEndereco(e, index)}
                    isLoading={false}
                    user={null}
                    enderecoDefaultValue={dado?.endereco}
                    habilitarEnderecoExterior={true}
                  />
                </Box>
                <Box>
                  {dado?.documentos && dado?.documentos.length > 0 && (
                    <Arquivo
                      setDados={(e) => handlerChangeDocumento(e, index)}
                      items={dado?.documentos}
                    />
                  )}
                </Box>
              </Fragment>
            ))}
        </Grid>
      </div>
    </>
  );
}
